import { Injectable } from '@angular/core';

import {
  BehaviorSubject,
  Observable,
  Subject
} from 'rxjs';

import { IItem } from './entities/sidebar.interfaces';

@Injectable({
  providedIn: 'root'
})
export class SidebarService {
  toggleMenu$: Observable<void>;
  changedItem$: Observable<number>;
  hoveredItem$: Observable<IItem>;

  private _toggleMenu: Subject<void> = new Subject();
  private _changedItem: Subject<number> = new Subject();

  private _hoveredItem: BehaviorSubject<IItem> = new BehaviorSubject(null);

  private _lastHoveredItem: IItem;
  private _specificItem: number;
  private _subMenuHoverOpened: boolean;
  private _subMenuOpened: boolean;

  constructor() {
    this.toggleMenu$ = this._toggleMenu.asObservable();
    this.changedItem$ = this._changedItem.asObservable();
    this.hoveredItem$ = this._hoveredItem.asObservable();
  }

  get hoveredItem(): IItem {
    return this._hoveredItem.getValue();
  }

  get specificItem(): number {
    return this._specificItem;
  }

  get subMenuHoverOpened(): boolean {
    return this._subMenuHoverOpened;
  }

  get subMenuOpened(): boolean {
    return this._subMenuOpened;
  }

  toggleMenu(): void {
    this._toggleMenu.next();
  }

  setSpecificItem(specificItem?: number): void {
    this._specificItem = specificItem;

    if (specificItem) {
      this._changedItem.next(specificItem);
    }
  }

  selectSubItem(): void {
    this._subMenuHoverOpened = false;
  }

  hoverItem(hoveredItem?: IItem): void {
    this._hoveredItem.next(hoveredItem || this._lastHoveredItem);
    this._lastHoveredItem = this.hoveredItem;
    this._subMenuHoverOpened = true;
  }

  blurItem(): void {
    this._subMenuHoverOpened = false;
    this._hoveredItem.next(undefined);
  }

  submenuClose(): void {
    this._subMenuHoverOpened = false;
  }

  toggleSubMenu(value?: boolean): void {
    this._subMenuOpened =
      value !== undefined ?
        value :
        !this._subMenuOpened;
  }
}
