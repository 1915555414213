import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  AbstractControl,
  Validators,
} from '@angular/forms';

import { Observable, of } from 'rxjs';
import { map, mergeMap, takeUntil, tap } from 'rxjs/operators';

import { BaseClass } from 'src/app/core/base-class';
import {
  Category,
  LoadedReprovation,
  SubmissionReprovation,
} from 'src/app/shared/model/reprovation';
import {
  ResponseSubReason,
  ResponseReason,
} from 'src/app/shared/model/refusal-reasons.model';
import { Reason } from 'src/app/shared/model/refusal-reasons.model';
import { ISingleSelect } from 'src/app/components/form-components/entities/form-components.interfaces';
import { RefusalReasonService } from 'src/app/services/refusal-reason.service';

@Component({
  selector: 'app-reprovation',
  templateUrl: './reprovation.component.html',
  styleUrls: ['./reprovation.component.scss'],
})
export class RefusalComponent extends BaseClass implements OnInit {
  form: UntypedFormGroup;
  filteredReasons: ISingleSelect[];
  categories: Category[];
  preview: string;
  forceTouch: boolean;
  loaderSearch: boolean;
  refusalReasons: Reason[];

  filteredReasons$: Observable<ISingleSelect[]>;

  private _disabled: boolean;

  @Input() set disabled(disabled: boolean) {
    this._disabled = disabled;
    this._disabled ?
      this.message.disable() :
      this.enableMessage();

    this.getCategoriesList();

    if (!this._disabled) {
      this.reasons.updateValueAndValidity();
    }
  }

  @Input() set reprovation({ reasons, label = '', message }: LoadedReprovation) {
    if (!this.message.value) {
      this.reason.setValue({ id: '', label }, { emitEvent: false });
      this.message.setValue(message, { emitEvent: false });
    }

    this.reasons.setValue(reasons, { emitEvent: false });
  }

  @Input() set saved(clickedSave) {
    this.forceTouch = clickedSave;

    if (
      !this._disabled &&
      this.reasons.value?.length &&
      this.reason.value?.id?.length &&
      clickedSave
    ) {
      this.setFormTouched();
    }
  }

  @Output() onChange = new EventEmitter<SubmissionReprovation>();

  constructor(
    private formBuilder: UntypedFormBuilder,
    private refusalReasonService: RefusalReasonService,
  ) {
    super();

    this.form = this.formBuilder.group({
      reasons: [[]],
      reason: [null],
      message: [{ value: '', disabled: true }],
    });
  }

  ngOnInit(): void {
    this.form.valueChanges.subscribe(() =>
      this.onChange.emit({
        reasons_ids: this.reasons.value?.map(({ id }) => id) || [],
        message: this.message.value,
      })
    );

    this.reason.valueChanges.subscribe((value) => {
      const selectedReason = this.refusalReasons
        .find(({ id }) => id === value.id);

      this.message.setValue(selectedReason?.message || '');
      this.enableMessage();
    });

    this.filteredReasons$ = this.reasons.valueChanges.pipe(
      tap(() => (this.loaderSearch = true)),
      mergeMap((reasons: Category[]) => {
        return !reasons?.length ? of([]) : this.refusalReasonService.getList(
          reasons?.map(({ id }: Category) => id),
        ).pipe(tap(response => this.refusalReasons = response))
      }),
      map((reasons: Reason[]) => {
        const singleSelects: ISingleSelect[] = reasons.map(
          ({ id, message, title }) => ({
            id,
            description: message,
            label: title,
          }),
        );

        singleSelects.unshift({ id: 'new', label: 'Nova mensagem' });

        return singleSelects;
      }),
      tap(() => (this.loaderSearch = false)),
    );

    this.getCategoriesList();
  }

  get disabled(): boolean {
    return this._disabled;
  }

  get reasons(): AbstractControl {
    return this.form.get('reasons');
  }

  get reason(): AbstractControl {
    return this.form.get('reason');
  }

  get message(): AbstractControl {
    return this.form.get('message');
  }

  reasonsChanged(reasons: Category[]): void {
    this.reasons.setValue(reasons);

    if (!reasons?.length) {
      this.reason.setValue(null);
      this.message.setValue('');
      this.message.disable();
    }
  }

  private setFormTouched(): void {
    this.message.setValidators(Validators.required);
    this.enableMessage();
    this.form.markAllAsTouched();
  }

  private getCategoriesList(): void {
    if (!this.disabled && !this.categories?.length) {
      this.refusalReasonService
        .getCategories()
        .pipe(
          map((reasons: ResponseReason[]) => this.buildReasons(reasons)),
          takeUntil(this.unsubscribe),
        )
        .subscribe((categories: Category[]) => (this.categories = categories));
    }
  }

  private buildReasons(
    items: ResponseReason[] | ResponseSubReason[],
    isChild?: boolean,
  ): Category[] {
    const categories: Category[] = [];

    items.forEach((reason: ResponseReason | ResponseSubReason) => {
      const children = (reason as ResponseReason)?.reasons,
        category: Category = {
          id: reason.id,
          name: reason.label,
        };

      if (children?.length) {
        categories.push({
          ...category,
          children: this.buildReasons(children, true),
        });
      } else if (isChild) {
        categories.push(category);
      }
    });

    return categories;
  }

  private enableMessage(): void {
    if (this.reasons.value?.length) {
      this.message.enable();
    }
  }
}
