import { NgZone } from '@angular/core';
import { environment } from '../environments/environment';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { ɵAngularFireSchedulers } from '@angular/fire';
import { getApps } from '@angular/fire/app';

export class AngularFireAuthFactory {
    private platformId: Object;
    private zone: NgZone;
    private instances: Object;

    constructor(platformId: Object, zone: NgZone) {
        this.platformId = platformId;
        this.zone = zone;
        this.instances = {};
    }

    getInstance(instanceName: string): AngularFireAuth {
        if (!(instanceName in this.instances)) {
            this.instances[instanceName] = new AngularFireAuth(environment.firebase, instanceName, this.platformId, this.zone, new ɵAngularFireSchedulers(this.zone), null, null, null, null, null, null, null);
        }
        return this.instances[instanceName];
    }
}
